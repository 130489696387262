import { useMutation, useQuery } from '@apollo/client'
import { getOperationName } from '@apollo/client/utilities'

import { SectionOrderSchemaFragment } from './__generated__/graphql'
import { projectModifySectionsOrder } from './projects.cache'
import { projectsGetByIdQuery } from './projects.gql'
import {
  sectionsAllQuery,
  sectionCreate,
  sectionsGetById,
  sectionUpdate,
  sectionsDeleteByIds,
  sectionDuplicate,
  sectionUpdateHide,
  sectionsUpdateByIds,
  sectionsTransfer,
  blockOrderUpdate,
} from './sections.gql'

export const useSectionsAll = (
  projectId: string,
  isNoCache?: boolean,
  nextFetchPolicy?:
    | 'cache-and-network'
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby',
) =>
  useQuery(sectionsAllQuery, {
    variables: {
      projectId,
    },
    nextFetchPolicy,
    fetchPolicy: !isNoCache ? 'cache-only' : 'cache-and-network',
    skip: !projectId?.trim(),
    onError: (err) => console.error('"useSectionsAll" fn is crashed on operation: "useQuery"', err),
  })

export const useSectionsGetById = (id: string, projectId: string, isCache?: boolean) => {
  return useQuery(sectionsGetById, {
    variables: { id, projectId },
    fetchPolicy: !isCache ? 'cache-only' : 'cache-and-network',
    skip: !id?.trim(),
    onError: (err) =>
      console.error('"useSectionsGetById" fn is crashed on operation: "useQuery"', err),
  })
}

export const useSectionsCreate = (projectId: string) =>
  useMutation(sectionCreate, {
    update: (_cache, { data: item }) => {
      if (item?.data && item?.data.sectionsOrder) {
        projectModifySectionsOrder(projectId, item?.data.sectionsOrder)
      }
    },
    refetchQueries: [String(getOperationName(sectionsAllQuery))],
    onError: (err) =>
      console.error('"useSectionsCreate" fn is crashed on operation: "useMutation"', err),
  })

export const useSectionsUpdateById = () =>
  useMutation(sectionUpdate, {
    refetchQueries: [
      String(getOperationName(sectionsAllQuery)),
      String(getOperationName(projectsGetByIdQuery)),
    ],
    update: (_cache, { data: item }) => {
      if (item?.data && item?.data.sectionsOrder) {
        projectModifySectionsOrder(item.data.section?.projectId || '', item?.data.sectionsOrder)
      }
    },
    onError: (err) =>
      console.error('"useSectionsUpdateById" fn is crashed on operation: "useMutation"', err),
  })

export const useBlocksOrderUpdate = () =>
  useMutation(blockOrderUpdate, {
    onError: (err) =>
      console.error('"useBlocksOrderUpdate" fn is crashed on operation: "useMutation"', err),
  })

export const useSectionsUpdateByIds = (sectionsOrder?: SectionOrderSchemaFragment[]) =>
  useMutation(sectionsUpdateByIds, {
    refetchQueries: [
      String(getOperationName(sectionsAllQuery)),
      String(getOperationName(projectsGetByIdQuery)),
    ],
    update: (_cache, { data: item }) => {
      if (item?.data.length) {
        item?.data.forEach((section) =>
          projectModifySectionsOrder(section.projectId, sectionsOrder),
        )
      }
    },
    onError: (err) =>
      console.error('"useSectionsUpdateById" fn is crashed on operation: "useMutation"', err),
  })

export const useSectionUpdateHide = () => useMutation(sectionUpdateHide)

export const useSectionsDeleteById = (projectId: string) =>
  useMutation(sectionsDeleteByIds, {
    update: (_cache, { data: item }) => {
      if (item?.data.sectionsOrder) {
        projectModifySectionsOrder(projectId, item.data.sectionsOrder)
      }
    },
    onError: (err) =>
      console.error('"useSectionsDeleteById" fn is crashed on operation: "useMutation"', err),
  })

export const useSectionsDuplicate = (projectId: string) =>
  useMutation(sectionDuplicate, {
    update: (_cache, { data: item }) => {
      if (item?.data.data?.length) {
        item?.data.data.forEach((section) =>
          projectModifySectionsOrder(projectId, section.sectionsOrder),
        )
      }
    },
    refetchQueries: [String(getOperationName(sectionsAllQuery))],
    onError: (err) =>
      console.error('"useSectionsDuplicate" fn is crashed on operation: "useMutation"', err),
  })

export const useSectionsTransfer = () =>
  useMutation(sectionsTransfer, {
    onError: (err) =>
      console.error('"useSectionsTransfer" fn is crashed on operation: "useMutation"', err),
  })
