import { RichTextPanelItem } from '@leenda/editor/lib/elements'
import { useMount } from 'ahooks'
import lodash from 'lodash'
import { useCallback, useState } from 'react'

import { PreviewMode } from 'services/Store/Project/enums'

import { useElementCompleted } from '../../hooks/useElementCompleted'
import useSetActiveItemElement from '../../hooks/useSetActiveItemElement'
import { AccordionElementType } from './Accordion.types'
import AccordionItem from './AccordionItem'
import RootStyled from './styled/RootStyled'

const toMap = (items: RichTextPanelItem[]) =>
  items.reduce<Record<string, boolean>>((acc, item) => ({ ...acc, [item.value]: false }), {})

export enum Position {
  left = 'left',
  right = 'right',
}

const AccordionElement: AccordionElementType = ({
  block,
  element,
  styles,
  font,
  mode,
  state,
  waiting,
  setState,
  onChange,
}) => {
  const { items, multipleExpand = true, showFirstTab = false } = element.value
  const [viewedTabs, setViewedTabs] = useState(() => toMap(items))

  const allViewed = lodash.every(viewedTabs)

  const handleChoose = useCallback((value: string) => setState?.({ active: value }), [setState])

  const handleToggle = useCallback(
    (value: string) => {
      if (multipleExpand) {
        setState?.({ open: { ...state?.open, [value]: !state?.open?.[value] } })
      } else {
        setState?.({ open: { [value]: !state?.open?.[value] } })
      }
      if (!allViewed) {
        setViewedTabs((prev) => ({ ...prev, [value]: true }))
      }
    },
    [setState, allViewed, multipleExpand, state],
  )

  useElementCompleted(block?.uuid || '', element.id, allViewed)

  useMount(() => {
    if (showFirstTab) {
      setState?.({ open: { ...state?.open, [items[0].value]: true } })
      setViewedTabs((prev) => ({ ...prev, [items[0].value]: true }))
    }
  })

  const setActiveItem = useCallback(
    (value: string) => setState?.({ open: { ...state?.open, [value]: true } }),
    [setState, state?.open],
  )
  useSetActiveItemElement(items, block!, setActiveItem)

  if (mode.previewMode === PreviewMode.pdf) {
    return (
      <RootStyled $styles={styles}>
        {items.map((item, index) => (
          <AccordionItem
            block={block}
            element={element}
            font={font}
            index={index}
            item={item}
            key={item.value}
            mode={mode}
            styles={styles}
            isOpen
          />
        ))}
      </RootStyled>
    )
  }

  return (
    <RootStyled $styles={styles}>
      {items.map((item, index) => (
        <AccordionItem
          block={block}
          element={element}
          font={font}
          index={index}
          isOpen={state?.open?.[item.value]}
          item={item}
          key={item.value}
          mode={mode}
          onChange={onChange}
          onChoose={handleChoose}
          onToggle={handleToggle}
          styles={styles}
          waiting={waiting}
        />
      ))}
    </RootStyled>
  )
}

export default AccordionElement
