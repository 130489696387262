import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

const getBorderByPosition = (styles: ElementStyleCss<TabsSchemaType>) => {
  const position = styles.tabs.position as PositionTabsElementType
  const borderRadius = styles.view.borderRadius
  switch (position) {
    case PositionTabsElementType.top:
      return css`
        border-top-left-radius: ${borderRadius};
        border-top-right-radius: ${borderRadius};
      `
    case PositionTabsElementType.left:
      return css`
        border-bottom-left-radius: ${borderRadius};
        border-top-left-radius: ${borderRadius};
      `
    case PositionTabsElementType.right:
      return css`
        border-bottom-right-radius: ${borderRadius};
        border-top-right-radius: ${borderRadius};
      `
    default:
      return css`
        border-top-left-radius: ${borderRadius};
        border-top-right-radius: ${borderRadius};
      `
  }
}

const splitted = css`
  background: transparent;
  border: none;
  border-radius: 0;
`

const TabItemsScrollStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
}>`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  background: ${({ $styles }) => $styles.tabs.backgroundColor};
  ${({ $styles }) => getBorderByPosition($styles)};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && splitted};
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export default TabItemsScrollStyled
