import { PositionTabsElementType, TabsSchemaType } from '@leenda/editor/lib/brand'
import styled, { css } from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

import { DirectionTabsEnum } from '../types'
import { Z_INDEX_NAV_BUTTOS } from './TabElementStyled'

const BUTTON_INDENT = 8

const prevIconVertical = css`
  right: 0;
  top: ${BUTTON_INDENT}px;
  bottom: auto;
`

const nextIconVertical = css`
  left: 0;
  bottom: ${BUTTON_INDENT}px;
  top: auto;
`

const prevIconHorizontal = css`
  left: ${BUTTON_INDENT}px;
`

const nextIconHorizontal = css`
  right: ${BUTTON_INDENT}px;
`

const buttonIconsPositionVertical = ($iconSide: DirectionTabsEnum) => css`
  width: 100%;
  height: 32px;
  ${$iconSide === DirectionTabsEnum.next && nextIconVertical};
  ${$iconSide === DirectionTabsEnum.prev && prevIconVertical};
`

const buttonIconsPositionHorizontal = ($iconSide: DirectionTabsEnum) => css`
  ${$iconSide === DirectionTabsEnum.prev && prevIconHorizontal};
  ${$iconSide === DirectionTabsEnum.next && nextIconHorizontal};
`

const NavButtonContainerStyled = styled.div<{
  $styles: ElementStyleCss<TabsSchemaType>
  $iconSide: DirectionTabsEnum
  $isDesktop: boolean
}>`
  position: absolute;
  display: ${({ $isDesktop }) => ($isDesktop ? 'none' : 'flex')}; // show in parent hover
  width: 32px;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 20px;
  cursor: pointer;
  z-index: ${Z_INDEX_NAV_BUTTOS};
  color: ${({ $styles }) => $styles.iconsColor.color};
  ${({ $iconSide, $styles }) =>
    ($styles.tabs.position as PositionTabsElementType) === PositionTabsElementType.top
      ? buttonIconsPositionHorizontal($iconSide)
      : buttonIconsPositionVertical($iconSide)};
`

export default NavButtonContainerStyled
