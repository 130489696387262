import { LabeledGraphicFontSchemaType, LabeledGraphicSchemaType } from '@leenda/editor/lib/brand'
import { CONFIG_ELEMENTS, LabeledGraphicElementValue } from '@leenda/editor/lib/elements'
import { generateId } from '@leenda/editor/lib/utils/id'
import { SlateElementType, textToRtValue } from '@leenda/rich-text'
import { useState } from 'react'

import { DEMO_MODE } from 'services/Store/Project/constants'
import { t } from 'services/Translation'

import DemoWrapper from '../../decorators/DemoWrapper'
import { DEPRECATED_Position, IElementDemoProps } from '../../elements.types'
import { LabeledGraphicState } from './LabeledGraphic.types'
import Element from './LabeledGraphicElement'
import s from './LabeledGraphicElement.module.scss'

const DEMO_VALUE: LabeledGraphicElementValue = {
  image: null,
  type: 'image',
  items: [
    {
      value: generateId(),
      description: [
        ...textToRtValue(t('elements.labeledGraphic.value.title'), SlateElementType.heading4),
        ...textToRtValue(t('elements.labeledGraphic.value.description')),
      ],
      x: '25%',
      y: '25%',
      image: null,
      pointIcon: null,
    },
    {
      value: generateId(),
      description: [
        ...textToRtValue(t('elements.labeledGraphic.value.title'), SlateElementType.heading4),
        ...textToRtValue(t('elements.labeledGraphic.value.description')),
      ],
      x: '50%',
      y: '50%',
      image: null,
      pointIcon: null,
    },
    {
      value: generateId(),
      description: [
        ...textToRtValue(t('elements.labeledGraphic.value.title'), SlateElementType.heading4),
        ...textToRtValue(t('elements.labeledGraphic.value.description')),
      ],
      x: '75%',
      y: '75%',
      image: null,
      pointIcon: null,
    },
  ],
}

const DEMO_POSITION: DEPRECATED_Position = {
  width: 800,
  height: 600,
}

const LabeledGraphicDemo: React.FC<
  IElementDemoProps<LabeledGraphicSchemaType, LabeledGraphicFontSchemaType>
> = ({ styles, fonts }) => {
  const [state, setState] = useState<LabeledGraphicState | undefined>()

  const handleSetState = (value: LabeledGraphicState) => setState((prev) => ({ ...prev, ...value }))
  const rootStyles = {
    ...styles,
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      borderRadius: styles.image.borderRadius,
    },
  }

  return (
    <DemoWrapper position={DEMO_POSITION}>
      <div className={s.demo} style={styles.image}>
        <Element
          element={{
            id: `${CONFIG_ELEMENTS.labeledGraphic.type}-demo`,
            type: CONFIG_ELEMENTS.labeledGraphic.type,
            value: DEMO_VALUE,
            style: {},
            font: {},
          }}
          font={fonts}
          mode={DEMO_MODE}
          setState={handleSetState}
          state={state}
          //@ts-ignore
          styles={rootStyles}
        />
      </div>
    </DemoWrapper>
  )
}

export default LabeledGraphicDemo
