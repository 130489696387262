import { FONT, SchemaKeys } from '@leenda/editor/lib/brand'
import { ElementSchemaDeviceType, StyleValue } from '@leenda/editor/lib/elements'
import * as R from 'ramda'
import { useEffect, useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import { DeviceMode } from 'services/Store/Project/enums'
import { MapType } from 'services/Store/Project/types'
import { validateFontFamilyCss } from 'utils/font'

import { BrandContext } from './BrandContext'
import {
  BrandType,
  ElementFontCss,
  ElementFontValue,
  ElementStyleCss,
  ElementStyleValue,
  ExtractFont,
  ExtractStyle,
} from './types'
import { mergeWithDeviceMode } from './utils'
import {
  collectCssVars,
  merge,
  replaceImageIdToPath,
  unwrapStyleSchema,
  validateCss,
} from './utils'

export const useBrandVars = (brand: BrandType) => {
  useEffect(() => {
    const themeCss = brand.theme
    const style = document.documentElement.style
    R.forEachObjIndexed((value, key) => {
      if (key.toLowerCase().includes('fontfamily')) {
        value = validateFontFamilyCss(value)
      }
      style.setProperty(`--brand-${String(key)}`, String(validateCss(value, key)))
    }, themeCss)
    return () => {
      R.forEachObjIndexed((_, key) => style.removeProperty(`--brand-${String(key)}`), themeCss)
    }
  }, [brand.theme])
}

export const useBrandTheme = () => useContextSelector(BrandContext, (brand) => brand.theme)

export const useElementStyleValues = <S extends SchemaKeys>(
  type: S,
  device: DeviceMode,
  style: ElementSchemaDeviceType = {},
) => {
  const styleSchema = useContextSelector(BrandContext, (context) => context.schema[type].style)

  return useMemo(() => {
    const brand = unwrapStyleSchema(styleSchema)
    const final = R.mergeDeepRight(brand, style)
    return mergeWithDeviceMode(final, device)
  }, [styleSchema, style, device]) as ElementStyleValue<ExtractStyle<S>>
}

export const useElementDefaultValues = <S extends SchemaKeys>(
  type: S,
  style: ElementSchemaDeviceType = {},
  font: ElementSchemaDeviceType = {},
) => {
  const styleSchema = useContextSelector(BrandContext, (context) => context.schema[type].style)
  const fontBrand = useContextSelector(BrandContext, (context) => context.font)
  const fontSchema = useContextSelector(BrandContext, (context) => context.schema[type].font)

  return useMemo(() => {
    const brandStyle = unwrapStyleSchema(styleSchema)
    const finalStyle = R.mergeDeepRight(brandStyle, style)

    const brandFont = unwrapStyleSchema(merge(fontBrand, fontSchema as typeof fontBrand))
    const baseFont = merge(
      R.mapObjIndexed(R.prop('base'), brandFont),
      R.mapObjIndexed(R.prop('base'), font),
    )

    const finalFont = merge(brandFont, font)
    const fontWithBase = R.mapObjIndexed(
      (obj, key) => R.mapObjIndexed((font) => merge(baseFont[key], font), obj),
      finalFont,
    )
    return {
      style: {
        [DeviceMode.desktop]: mergeWithDeviceMode(finalStyle, DeviceMode.desktop),
        [DeviceMode.tablet]: mergeWithDeviceMode(finalStyle, DeviceMode.tablet),
        [DeviceMode.mobile]: mergeWithDeviceMode(finalStyle, DeviceMode.mobile),
      },
      font: {
        [DeviceMode.desktop]: R.pick(
          R.keys(fontSchema.desktop),
          mergeWithDeviceMode(merge(finalFont, fontWithBase), DeviceMode.desktop),
        ),
        [DeviceMode.tablet]: R.pick(
          R.keys(fontSchema.desktop),
          mergeWithDeviceMode(merge(finalFont, fontWithBase), DeviceMode.tablet),
        ),
        [DeviceMode.mobile]: R.pick(
          R.keys(fontSchema.desktop),
          mergeWithDeviceMode(merge(finalFont, fontWithBase), DeviceMode.mobile),
        ),
      },
    }
  }, [fontBrand, styleSchema, fontSchema, font, style]) as ElementStyleValue<ExtractStyle<S>>
}

export const useElementStyleCss = <S extends SchemaKeys>(
  type: S,
  device: DeviceMode,
  style: ElementSchemaDeviceType = {},
) => {
  const values = useElementStyleValues(type, device, style) as MapType<Record<string, StyleValue>>

  return useMemo(() => {
    replaceImageIdToPath(values)
    values.root = { ...values.root, ...collectCssVars(values) }
    return R.mapObjIndexed(R.mapObjIndexed(validateCss), values)
  }, [values]) as ElementStyleCss<ExtractStyle<S>>
}

export const useElementFontValues = <S extends SchemaKeys>(
  type: S,
  device: DeviceMode,
  font: ElementSchemaDeviceType = {},
) => {
  const fontBrand = useContextSelector(BrandContext, (context) => context.font)
  const fontSchema = useContextSelector(BrandContext, (context) => context.schema[type].font)

  return useMemo(() => {
    const brand = unwrapStyleSchema(merge(fontBrand, fontSchema as typeof fontBrand))
    const base = merge(
      R.mapObjIndexed(R.prop('base'), brand),
      R.mapObjIndexed(R.prop('base'), font),
    )

    const final = merge(brand, font)
    const withBase = R.mapObjIndexed(
      (obj, key) => R.mapObjIndexed((font) => merge(base[key], font), obj),
      final,
    )
    return R.pick(R.keys(fontSchema.desktop), mergeWithDeviceMode(merge(final, withBase), device))
  }, [fontSchema, fontBrand, font, device]) as ElementFontValue<ExtractFont<S>>
}

export const useElementFontCss = <S extends SchemaKeys>(
  type: S,
  device: DeviceMode,
  font: ElementSchemaDeviceType = {},
) => {
  const values = useElementFontValues(type, device, font) as MapType<Record<string, StyleValue>>

  return useMemo(
    () => R.mapObjIndexed(R.mapObjIndexed(validateCss), values),
    [values],
  ) as ElementFontCss<ExtractFont<S>>
}

export const useFontValues = (device?: DeviceMode) => {
  const brand = useContextSelector(BrandContext, (context) => unwrapStyleSchema(context.font))

  return useMemo(() => {
    const base = R.mapObjIndexed(R.prop('base'), brand)
    const withBase = R.mapObjIndexed(
      (obj, key) => R.mapObjIndexed((font) => merge(base[key], font), obj),
      brand,
    )

    return device ? mergeWithDeviceMode(merge(brand, withBase), device) : merge(brand, withBase)
  }, [brand, device]) as ElementFontValue
}

export const useFontCss = (device: DeviceMode) => {
  const values = useFontValues(device) as MapType<Record<string, StyleValue>>

  return useMemo(() => {
    return R.mapObjIndexed(R.mapObjIndexed(validateCss), values)
  }, [values]) as ElementFontCss<typeof FONT.desktop>
}
