import { SCHEMA } from '@leenda/editor/lib/brand'

import { genFormSchema, FONT_SCHEMA_FIELDS } from 'services/Branding/genFormSchema'
import { t } from 'services/Translation'

export const STYLE_FORM_SCHEMA = genFormSchema(SCHEMA.button.style, {
  icon: {
    META: { hidden: true },
    backgroundImage: { label: t('elements.button.schema.icon'), params: { preview: false } },
  },
  button: {
    META: { label: t('elements.button.tags.active') },
    backgroundImage: {
      label: t('elements.button.schema.backgroundImage'),
      params: {
        preview: false,
      },
    },
    backgroundColor: {},
  },
  hover: {
    META: { hidden: true },
    backgroundColor: {
      label: t('style.hoverColor'),
      params: {
        resettable: true,
      },
    },
  },
  disabled: {
    META: { label: t('elements.button.tags.inactive') },
    backgroundImage: {
      label: t('elements.button.schema.backgroundImage'),
      params: { preview: false },
    },
    backgroundColor: {},
  },
  border: {
    META: { label: t('elements.button.tags.border') },
    borderRadius: {},
    borderStyle: {},
    borderColor: {},
    borderWidth: {},
  },
  padding: {
    META: { label: t('elements.button.tags.size') },
    paddingTop: { label: t('elements.button.schema.vertical') },
    paddingLeft: { label: t('elements.button.schema.horizontal') },
  },
})

export const FONT_FORM_SCHEMA = genFormSchema(SCHEMA.button.font, {
  active: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.button.tags.active') },
  },
  disabled: {
    ...FONT_SCHEMA_FIELDS.base,
    META: { label: t('elements.button.tags.disabled') },
  },
})
