import { AudioSchemaType, VideoSchemaType } from '@leenda/editor/lib/brand'
import {
  CONFIG_ELEMENTS,
  EditorElement,
  AudioElementValue,
  VideoElementValue,
  ElementsTypes,
} from '@leenda/editor/lib/elements'
import { FileUsage } from '@leenda/editor/lib/files'
import { useMemo } from 'react'
import { urlValidation, getParent } from 'utils'

import { PLAYBACK_RATE_OPTIONS } from 'components/MediaPlayer/constants'
import { genDataField } from 'components/controls/Field'
import { Tooltip } from 'components/uiKit/Dropdown'
import Icon from 'components/uiKit/Icon'
import { KitSize } from 'components/uiKit/KitTypes'
import { genColorOptions } from 'services/Branding/constants/fields'
import { useBrandTheme } from 'services/Branding/hooks'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'

type MediaSchemaType = AudioSchemaType | VideoSchemaType
type MediaValue = AudioElementValue | VideoElementValue

type MediaElement = EditorElement<MediaValue, MediaSchemaType>

const genField = genDataField<Block>()

export const sourceType = genField({
  label: t('input.label.source'),
  name: 'sourceType',
  type: 'select',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.type === 'audio',
      options: [
        { value: 'file', label: t('input.option.file') },
        { value: 'url', label: t('input.option.link') },
      ],
      size: KitSize.S,
      fluid: true,
    }
  },
  effect: (block, changeData) => {
    const { value: sourceType, name } = changeData
    const { parentName } = getParent(name)
    const { parent } = getParent<MediaValue>(name, block)
    return {
      ...changeData,
      name: parentName,
      value: { ...CONFIG_ELEMENTS.audio.defaultValue, sourceType, percent: parent?.percent },
    }
  },
})

export const url = (value: AudioElementValue | VideoElementValue) =>
  genField({
    name: 'url',
    type: 'file',
    layout: 'horizontal',
    rules: [
      { required: true, message: t('input.placeholder.pasteLink') },
      {
        validator: (rule, value) =>
          typeof value === 'string' ? urlValidation(rule, value) : undefined,
      },
    ],
    params: (block, { name }) => {
      const { parent } = getParent<MediaValue>(name, block)

      return {
        hidden: parent?.sourceType === 'file',
        placeholder: t('input.placeholder.pasteLink'),
        size: KitSize.S,
        fileParams: value,
        fileType: 'video',
        source: 'url',
        preview: true,
      }
    },
    effect: async (block, changeData) => {
      const { value, name } = changeData
      const { parentName, parent } = getParent<MediaValue>(name, block)
      const url = String(value).includes('youtube.com')
        ? String(value).split('&list=')[0]
        : String(value)

      // const websiteValidated = validateUrl(String(value))
      // const checkedFileRequestResult = websiteValidated ? await checkUrlRequest(url) : {}
      // const checkedFileType =
      //   checkedFileRequestResult?.payload && websiteValidated && parent
      //     ? checkedFileRequestResult.payload.mediaType.includes(parent.type)
      //     : undefined
      //
      // if (checkedFileRequestResult?.payload && !checkedFileType) {
      //   notify({ type: NotificationType.error, message: t('notify.wrongFileType') })
      //   throw new Error('Error with File')
      // }
      //
      // if (checkedFileRequestResult?.errors) {
      //   notify({ type: NotificationType.error, message: t('notify.fileNotFound') })
      //   throw new Error('Error with File')
      // }

      return {
        ...changeData,
        name: parentName,
        value: {
          ...CONFIG_ELEMENTS.audio.defaultValue,
          sourceType: 'url',
          url,
          blackout: parent?.blackout,
          percent: parent?.percent,
        },
      }
    },
  })

export const file = (value: AudioElementValue | VideoElementValue) =>
  genField({
    name: 'file',
    type: 'file',
    layout: 'horizontal',
    params: (block, { name }) => {
      const { parent } = getParent<MediaElement>(name, block, 2)

      return {
        hidden: parent?.value.sourceType === 'url',
        fileType: parent?.type as 'audio' | 'video',
        nullable: true,
        fileParams: value,
        preview: true,
      }
    },
    effect: (block, changeData) => {
      const { value: file, name } = changeData
      const { parent, parentName } = getParent<MediaValue>(name, block)

      return {
        ...changeData,
        name: parentName,
        value: {
          ...CONFIG_ELEMENTS.audio.defaultValue,
          sourceType: 'file',
          file,
          blackout: parent?.blackout,
          coverImage: parent?.coverImage,
        },
      }
    },
  })
export const coverImage = genField({
  name: 'coverImage',
  type: 'file',
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      hidden: parent?.type !== 'video',
      fileType: 'image',
      nullable: true,
      preview: true,
    }
  },
})

export const autoPlay = genField({
  name: 'autoPlay',
  type: 'segmented',
  label: t('elements.media.form.autoPlay'),
  layout: 'horizontal',
})

export const repeat = genField({
  name: 'repeat',
  type: 'segmented',
  label: t('elements.media.form.repeat'),
  layout: 'horizontal',
})

export const controls = genField({
  name: 'controls',
  type: 'segmented',
  label: (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {t('elements.media.form.controls')}
      <Tooltip overlay={t('input.tooltip.mediaPlayerControls')}>
        <Icon name='builderTooltip' size={KitSize.S} />
      </Tooltip>
    </div>
  ),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaElement>(name, block, 2)

    return {
      hidden: parent?.type !== 'video',
    }
  },
})

export const volume = genField({
  name: 'volume',
  type: 'slider',
  label: t('elements.media.form.volume'),
  layout: 'horizontal',
  params: { showInput: true, min: 0, max: 100 },
})

export const playbackRate = genField({
  name: 'playbackRate',
  type: 'select',
  label: t('elements.media.form.playbackRate'),
  layout: 'horizontal',
  params: {
    size: KitSize.S,
    options: PLAYBACK_RATE_OPTIONS,
    fluid: true,
  },
})

export const breakpoints = genField({
  name: 'file.params.breakpoints',
  type: 'timeRange',
  label: t('elements.media.form.slice'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<MediaValue>(name, block, 3)
    const { duration } = parent || {}

    return {
      maxTime: duration,
      disabled: !duration,
      format: duration && duration > 3600 ? 'HH:mm:ss' : 'mm:ss',
      placeholder: duration && duration > 3600 ? '00:00:00' : '00:00',
      suffixIcon: null,
      hideDisabledOptions: true,
    }
  },
})

export const blackout = genField({
  name: 'blackout',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  useParams: (block, { name }) => {
    const theme = useBrandTheme()
    const options = useMemo(() => genColorOptions(theme), [theme])
    const { parent } = getParent<MediaElement>(name, block, 2)
    return {
      options,
      hidden: parent?.type !== 'video',
      labeled: true,
    }
  },
})

export const accessibility = genField({
  name: 'coverImage.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<FileUsage>(name, block)
    return {
      placeholder: t('input.placeholder.altText'),
      hidden: !parent?.id,
    }
  },
})

export const percent = (type: ElementsTypes) =>
  genField({
    name: 'percent',
    type: 'slider',
    label: t('input.label.percent'),
    layout: 'horizontal',
    info:
      type === 'video'
        ? t('elements.media.form.videoPercentHint')
        : t('elements.media.form.audioPercentHint'),
    params: {
      max: 100,
      min: 0,
      showInput: true,
    },
  })

export const captions = genField({
  name: 'captions',
  type: 'file',
  layout: 'horizontal',
  params: {
    fileType: 'captions',
    nullable: true,
  },
})

export const enableCCByDefault = genField({
  name: 'enableCCByDefault',
  type: 'segmented',
  label: t('elements.media.form.enableCCByDefault'),
  layout: 'horizontal',
  defaultValue: false,
})
