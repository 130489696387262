import { FileImageUsage } from '@leenda/editor/lib/files'

import { genDataField } from 'components/controls/Field'
import RealtimeField from 'components/controls/RealtimeField'
import Padding from 'components/editor-v3/Editor/Toolbar/Padding'
import Collapse from 'components/uiKit/Collapse'
import { Typography } from 'components/uiKit/Typography'
import { FULL_COLLAPSE_PADDING } from 'constants/styles'
import { Block } from 'services/Store/Project/types'
import { t } from 'services/Translation'
import { chainName, getParent } from 'utils/form'

import { TestHotspotValueFormType } from './TestHotspotElement.types'

const genField = genDataField<Block>()

const responseOptions = [
  { value: 'single', label: t('elements.testHotspot.form.response.any') },
  { value: 'all', label: t('elements.testHotspot.form.response.all') },
]

const typeOptions = [
  { value: 'icon', label: t('input.option.icons') },
  { value: 'withoutIcon', label: t('input.option.withoutIcon') },
]

const response = genField({
  name: 'response',
  label: t('elements.testHotspot.form.response.label'),
  type: 'segmented',
  layout: ['horizontal', 'solid'],
  defaultValue: 'all',
  params: { options: responseOptions },
})

const image = genField({
  name: 'image',
  type: 'file',
  layout: 'horizontal',
  params: { fileType: 'image', nullable: true, preview: true },
})

const type = genField({
  name: 'type',
  type: 'select',
  label: t('input.label.type'),
  layout: 'horizontal',
  params: { options: typeOptions, fluid: true },
  defaultValue: 'icon',
})

export const accessibility = genField({
  name: 'image.accessibility',
  type: 'text',
  label: t('input.label.accessibility'),
  layout: 'horizontal',
  params: (block, { name }) => {
    const { parent } = getParent<FileImageUsage>(name, block)
    return { placeholder: t('input.placeholder.altText'), hidden: !parent?.id }
  },
})

export const blackout = genField({
  name: 'blackout',
  type: 'color',
  label: t('input.label.blackout'),
  layout: 'horizontal',
  params: {
    labeled: true,
  },
})

const TestHotspotForm: TestHotspotValueFormType = ({ name }) => {
  return (
    <>
      <Padding bottom sides top>
        <RealtimeField config={chainName(name, response)} />
        <Typography styleType='hint'>{t('elements.testHotspot.form.hint')}</Typography>
      </Padding>
      <Collapse
        header={t('elements.labeledGraphic.form.image')}
        iconPosition='right'
        name='image'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, image)} />
        <RealtimeField config={chainName(name, blackout)} />
        <RealtimeField config={chainName(name, accessibility)} />
      </Collapse>
      <Collapse
        header={t('elements.testHotspot.tags.spot')}
        iconPosition='right'
        name='spot'
        paddings={FULL_COLLAPSE_PADDING}
        initialOpen
      >
        <RealtimeField config={chainName(name, type)} />
      </Collapse>
    </>
  )
}

export default TestHotspotForm
