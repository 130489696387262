"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("../../types");
const constants_1 = require("../../../elements/elements/Tabs/constants");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    view: {
        type: (0, genStyle_1.genStyle)({ value: types_1.ViewTabsElementType.solid }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 16 }),
    },
    border: {
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    tabs: {
        position: (0, genStyle_1.genStyle)({ value: types_1.PositionTabsElementType.top }),
        tabSize: (0, genStyle_1.genStyle)({ value: types_1.SizeTabsElementType.hug }),
        width: (0, genStyle_1.genStyle)({ value: 176 }),
        textAlign: (0, genStyle_1.genStyle)({ value: 'center' }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    activeTab: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
    },
    borderTab: {
        borderRadius: (0, genStyle_1.genStyle)({ value: 16 }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    splitter: {
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    content: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
        borderRadius: (0, genStyle_1.genStyle)({ value: 16 }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    icons: {
        show: (0, genStyle_1.genStyle)({ value: true }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    iconsHover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.focusedColor }),
    },
    iconsColor: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.lightTextColor }),
    },
    iconLeft: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    iconRight: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    indents: {
        padding: (0, genStyle_1.genStyle)({ value: [14, 20, 14, 20] }),
        margin: (0, genStyle_1.genStyle)({ value: [32, 32, 32, 32] }),
    },
};
const fontSchema = {
    selected: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
    tabs: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
