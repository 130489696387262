"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("../../../elements/course/Sidebar/constants");
const CourseStructure_1 = require("../../../elements/elements/CourseStructure");
const font_1 = require("../../font");
const theme_1 = require("../../theme");
const types_1 = require("../../types");
const genStyle_1 = require("../genStyle");
const styleSchema = {
    menu: {
        type: (0, genStyle_1.genStyle)({ value: types_1.SidebarMenuType.drawer }),
        position: (0, genStyle_1.genStyle)({ value: types_1.MenuPositionType.left }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryBackgroundColor }),
    },
    value: {
        showLogo: (0, genStyle_1.genStyle)({ value: false }),
        showOverlay: (0, genStyle_1.genStyle)({ value: true }),
        showDescription: (0, genStyle_1.genStyle)({ value: true }),
        width: (0, genStyle_1.genStyle)({ value: 370 }),
    },
    item: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    item_hover: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.hoverColor }),
    },
    item_disable: {
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
    },
    progress: {
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        showProgress: (0, genStyle_1.genStyle)({ value: true }),
        showDescriptionProgress: (0, genStyle_1.genStyle)({
            value: types_1.ProgressDescriptionPosition.left,
        }),
    },
    verticalProgress: {
        progressPosition: (0, genStyle_1.genStyle)({ value: CourseStructure_1.ProgressIconPosition.left }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.primaryColor }),
    },
    margins: {
        offsetFromIcon: (0, genStyle_1.genStyle)({ value: 8 }),
        offsetYFromSection: (0, genStyle_1.genStyle)({ value: 12 }),
        offsetFromProgress: (0, genStyle_1.genStyle)({ value: 16 }),
        offsetFromCollapse: (0, genStyle_1.genStyle)({ value: 8 }),
    },
    search: {
        showSearch: (0, genStyle_1.genStyle)({ value: true }),
        backgroundColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.neutralColor }),
        color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }),
        borderRadius: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderRadius }),
        borderStyle: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderStyle }),
        borderColor: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.borderColor }),
        borderWidth: (0, genStyle_1.genStyle)({ value: 1 }),
    },
    collapse: {
        initialOpen: (0, genStyle_1.genStyle)({ value: types_1.InitialOpen.expanded }),
        collapsePosition: (0, genStyle_1.genStyle)({ value: CourseStructure_1.CollapseIconPosition.none }),
    },
    collapseIcon: {
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
        backgroundOpen: (0, genStyle_1.genStyle)({ value: null }),
    },
    chapterIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: true }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    sectionIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: true }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
    testIcons: {
        showIcons: (0, genStyle_1.genStyle)({ value: true }),
        backgroundImage: (0, genStyle_1.genStyle)({ value: null }),
    },
};
const fontSchema = {
    title: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.h4),
    chapter: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
    section: Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base),
    description: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 11 }) }),
    search: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
    progress: Object.assign(Object.assign({}, font_1.FONT_SCHEMA_EMPTY.base), { fontSize: (0, genStyle_1.genStyle)({ value: 13 }), color: (0, genStyle_1.genStyle)({ theme: theme_1.KEYS.secondaryTextColor }) }),
};
exports.default = { [constants_1.TYPE]: { style: styleSchema, font: fontSchema } };
