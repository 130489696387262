import {
  PositionTabsElementType,
  TabsSchemaType,
  ViewTabsElementType,
} from '@leenda/editor/lib/brand'
import styled from 'styled-components'

import { ElementStyleCss } from 'services/Branding/types'

export const Z_INDEX_TEXT_AND_SPLITER = 2
export const Z_INDEX_NAV_BUTTOS = 3
export const Z_INDEX_TABS_LIST = 1
export const Z_INDEX_TAB_ACTIVE = 1
export const Z_INDEX_TAB_MARKER = 1
export const DEFAULT_INDENT_TAB = 4
export const SPLITTER_WIDTH = '40px'

export const position = {
  [PositionTabsElementType.top]: 'column',
  [PositionTabsElementType.left]: 'row',
  [PositionTabsElementType.right]: 'row-reverse',
}

const TabElementStyled = styled.div<{ $styles: ElementStyleCss<TabsSchemaType> }>`
  display: flex;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  flex-direction: ${({ $styles }) =>
    position[$styles.tabs.position as PositionTabsElementType] || 'column'};
  ${({ $styles }) => $styles.view.type === ViewTabsElementType.splitted && 'gap: 16px'};
`

export default TabElementStyled
